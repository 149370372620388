<template>
  <section v-once class="duo-combo wrapper m-xxxl">
    <div
      v-for="(duo, index) in content.duos"
      :key="index"
      class="duo-combo__item"
    >
      <component
        :is="components[component.component]"
        v-for="component in duo"
        :key="`${index}-${component.component}`"
        :content="component.content"
        class="duo-combo__item__component"
      ></component>
    </div>
  </section>
</template>

<script lang="ts">
type DuoType = 'colored_picture' | 'quick_access' | 'typeset'

interface DuoComponent<T extends DuoType> {
  component: T
  content: Object
}
type Duo = DuoComponent<DuoType>[]
interface DuoCombo {
  duos: Duo[]
}

export type { DuoComponent, Duo, DuoCombo }
</script>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<DuoCombo>,
    required: true,
  },
})

const components: Partial<
  Record<DuoType, ReturnType<typeof defineAsyncComponent>>
> = {
  /* eslint-disable camelcase */
  colored_picture: defineAsyncComponent(
    () => import('@/components/duo/Picture.vue')
  ),
  quick_access: defineAsyncComponent(
    () => import('@/components/duo/Simulator.vue')
  ),
  typeset: defineAsyncComponent(() => import('@/components/duo/Wysiwyg.vue')),
  /* eslint-enable camelcase */
}
</script>

<style lang="scss" scoped>
$bp: m;

.duo-combo {
  list-style-type: none;

  @include mq($until: $bp) {
    padding-right: 0;
    padding-left: 0;
  }
}

.duo-combo__item {
  @include mq($bp) {
    display: flex;
    align-items: center;
    min-height: 70rem;
  }

  @include mq(l) {
    min-height: 70rem;
  }
}

.duo-combo__item__component {
  @include mq($bp) {
    width: 50%;
  }
}
</style>
